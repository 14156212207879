import './sass/style.scss';
import Swiper, { Pagination, Autoplay } from 'swiper';
import 'swiper/swiper.min.css';
import $ from 'jquery';
// import 'daterangepicker';
// import 'daterangepicker/daterangepicker.css';
// import 'selectize/dist/js/standalone/selectize';
// import 'selectize/dist/css/selectize.css';
// import 'tippy.js/dist/tippy.css';
// import 'tippy.js/animations/scale.css';

if (IS_PRODUCTION) {
    const Sentry = require('@sentry/browser');
    Sentry.init({
        dsn: 'https://82f5be72f6394a8baffa4cd260fbdeb2@glitch.polis812.ru/6',
        release: 'landing@' + VERSION,
    });
}

Swiper.use([Pagination, Autoplay]);

let partnerSwiper = function() {
    new Swiper('.partners__swiper', {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 55,
        autoplay: {
            delay: 5000,
        },
        speed: 800,
        loop: true,
        pagination: {
            el: '.partners__swiper-pagination',
        },
        centerInsufficientSlides: true,
        breakpoints: {
            768: {
                slidesPerView: 4,
                slidesPerGroup: 4,
            },
            1024: {
                slidesPerView: 6,
                slidesPerGroup: 6,
                spaceBetween: 90,
            },
            1210: {
                slidesPerView: 7,
                slidesPerGroup: 7,
                spaceBetween: 55,
            }
        }
    });
};

const isMobile = window.matchMedia('(max-width: 767px');

let sizeItems = function (item, container) {
    if (window.innerWidth<=650) {
        $(item).css('width', ($(container).first().innerWidth()) + 'px');
    }
    else if (window.innerWidth<=1200) {
        $(item).css('width', Math.floor(($(container).first().innerWidth() - 20) / 2 )+ 'px');
    }
    else {
        $(item).css('width',  Math.floor((($(container).first().innerWidth() - 40) / 3)) + 'px');
    }

};

let sizeSmallItems = function (item, container) {
    if (window.innerWidth<=650) {
        $(item).css('width', ($(container).first().innerWidth()) + 'px');
    }
    else if (window.innerWidth<=1200) {
        $(item).css('width', Math.floor(($(container).first().innerWidth() - 40) / 3 )+ 'px');
    }
    else {
        $(item).css('width',  Math.floor((($(container).first().innerWidth() - 60) / 4)) + 'px');
    }

};

let getItemSize = function (container) {
    if (window.innerWidth<=650) {
        return $(container).first().innerWidth() + 10;
    }
    else if (window.innerWidth<=1200) {
        return  ( Math.floor(($(container).first().innerWidth() - 20) / 2)) + 20;
    }
    else {
        return  Math.floor((($(container).first().innerWidth() - 40) / 3)) + 20;
    }
};

let getSmallItemsSize = function (container) {
    if (window.innerWidth<=650) {
        return $(container).first().innerWidth() + 10;
    }
    else if (window.innerWidth<=1200) {
        return  ( Math.floor(($(container).first().innerWidth() - 40) / 3)) + 20;
    }
    else {
        return  Math.floor((($(container).first().innerWidth() - 60) / 4)) + 20;
    }
};

let maxClick = 0;
let clickCount=0;

let setMaxClick = function () {
    let itemsCount = document.querySelectorAll('.advantages-list__item').length;
    if (window.innerWidth<=650) {
        maxClick = itemsCount - 1 ;
    }
    else if (window.innerWidth<=1200) {
        maxClick=itemsCount - 2;
    } else {
        maxClick=itemsCount - 3;
    }
    if ((window.innerWidth>1200 && itemsCount<=3) || (window.innerWidth>=650 && itemsCount<=2) || (window.innerWidth<=650 && itemsCount<=3)) {
        $('#arrow-right').removeClass('active');
        $('#arrow-right').addClass('inactive');
    }
    if (clickCount > maxClick) {
        clickCount=maxClick;
        $('.advantages-list').css('left', `-${(clickCount)*getItemSize('.advantages-container')}px`);
        $('#arrow-right').removeClass('active');
        $('#arrow-right').addClass('inactive');
    }
};

let maxClickReviews = 0;
let clickReviews = 0;

let setMaxClickReviews = function () {
    let itemsCount = document.querySelectorAll('.reviews-block__item').length;
    if (window.innerWidth<=650) {
        maxClickReviews = itemsCount - 1 ;
    }
    else if (window.innerWidth<=1200) {
        maxClickReviews=itemsCount - 2;
    } else {
        maxClickReviews=itemsCount - 3;
    }
    if ((window.innerWidth>1200 && itemsCount<=3) || (window.innerWidth>=650 && itemsCount<=2) || (window.innerWidth<=650 && itemsCount<=3)) {
        $('#arrow-right-reviews').removeClass('active');
        $('#arrow-right-reviews').addClass('inactive');
    }
    if (clickReviews > maxClickReviews) {
        clickReviews=maxClickReviews;
        $('.advantages-list').css('left', `-${(clickReviews)*getItemSize('.reviews-container')}px`);
        $('#arrow-right-reviews').removeClass('active');
        $('#arrow-right-reviews').addClass('inactive');
    }
};

const arrowSwiper = () => {
    setMaxClick();
    sizeItems('.advantages-list__item', '.advantages-container');
    $('#arrow-left').click( event => {
        if (event.target.classList.contains('active') && clickCount>0) {
            $('.advantages-list').css('left', `-${(clickCount-1)*getItemSize('.advantages-container')}px`);
            clickCount--;
            $('#arrow-right').removeClass('inactive');
            $('#arrow-right').addClass('active');
        }
        if (clickCount===0) {
            event.target.classList.remove('active');
            event.target.classList.add('inactive');
        }
    });
    $('#arrow-right').click(event => {
        if (event.target.classList.contains('active')&& clickCount<maxClick) {
            $('.advantages-list').css('left', `-${(clickCount+1)*getItemSize('.advantages-container')}px`);
            clickCount++;
            $('#arrow-left').removeClass('inactive');
            $('#arrow-left').addClass('active');
        }
        if (clickCount===maxClick) {
            event.target.classList.remove('active');
            event.target.classList.add('inactive');
        }
    });
};

const reviewsSwiper = () => {
    setMaxClickReviews();
    sizeItems('.reviews-block__item', '.reviews-container');
    $('#arrow-left-reviews').click( event => {
        if (event.target.classList.contains('active') && clickReviews>0) {
            $('.reviews-list').css('left', `-${(clickReviews-1)*getItemSize('.reviews-container')}px`);
            clickReviews--;
            $('#arrow-right-reviews').removeClass('inactive');
            $('#arrow-right-reviews').addClass('active');
        }
        if (clickReviews===0) {
            event.target.classList.remove('active');
            event.target.classList.add('inactive');
        }
    });
    $('#arrow-right-reviews').click(event => {
        if (event.target.classList.contains('active')&& clickReviews<maxClickReviews) {
            $('.reviews-list').css('left', `-${(clickReviews+1)*getItemSize('.reviews-container')}px`);
            clickReviews++;
            $('#arrow-left-reviews').removeClass('inactive');
            $('#arrow-left-reviews').addClass('active');
        }
        if (clickReviews===maxClickReviews) {
            event.target.classList.remove('active');
            event.target.classList.add('inactive');
        }
    });
};

let maxClickInsurances = 0;
let clickInsurances = 0;

let setMaxClickInsurances = function () {
    let itemsCount = document.querySelectorAll('.insurance-block__card').length;
    if (window.innerWidth<=650) {
        maxClickInsurances = Math.floor(itemsCount / 3) + (itemsCount%3===0? -1 : 0);
    }
    else if (window.innerWidth<=1200) {
        maxClickInsurances=itemsCount - 3;
    } else {
        maxClickInsurances=itemsCount - 4;
    }
    if ((window.innerWidth>1200 && itemsCount<=4) || (window.innerWidth>=650 && itemsCount<=3) || (window.innerWidth<=650 && itemsCount<=3)) {
        $('#arrow-right-insurances').removeClass('active');
        $('#arrow-right-insurances').addClass('inactive');
    }
    if (clickInsurances > maxClickInsurances) {
        clickInsurances=maxClickInsurances;
        $('.advantages-list').css('left', `-${(clickInsurances)*getItemSize('.reviews-container')}px`);
        $('#arrow-right-insurances').removeClass('active');
        $('#arrow-right-insurances').addClass('inactive');
    }
};

const insurancesSwiper = () => {
    setMaxClickInsurances();
    sizeSmallItems('.insurance-block__card', '.insurances__container');
    $('#arrow-left-insurances').click( event => {
        if (event.target.classList.contains('active') && clickInsurances>0) {
            $('.insurances__list').css('left', `-${(clickInsurances-1)*getSmallItemsSize('.insurances__container')}px`);
            clickInsurances--;
            $('#arrow-right-insurances').removeClass('inactive');
            $('#arrow-right-insurances').addClass('active');
        }
        if (clickInsurances===0) {
            event.target.classList.remove('active');
            event.target.classList.add('inactive');
        }
    });
    $('#arrow-right-insurances').click(event => {
        if (event.target.classList.contains('active')&& clickInsurances<maxClickInsurances) {
            $('.insurances__list').css('left', `-${(clickInsurances+1)*getSmallItemsSize('.insurances__container')}px`);
            clickInsurances++;
            $('#arrow-left-insurances').removeClass('inactive');
            $('#arrow-left-insurances').addClass('active');
        }
        if (clickInsurances===maxClickInsurances) {
            event.target.classList.remove('active');
            event.target.classList.add('inactive');
        }
    });
};


const getReviews = () => {
    let html = '';
    $.ajax({
        url: 'https://polis812.ru/api/reviews/get?type=vzr',
        method: 'GET',
    })
        .done((res) => {
            if(res.success) {
                res.data.forEach(review => {
                    html += `
                        <div
          class="reviews-block__item"
        >
          <div class="reviews-block__review">
              <div class="reviews-block__company">
                <div class="reviews-block__company-logo">
                  <img
                    src="${review.company.image}"
                    alt="Логотип компании ${review.company.name}"
                  />
                </div>
                <div class="reviews-block__company-rating">
                  <span>${ review.rating }</span>
                  ${isMobile.matches ? '<svg class="filled" viewBox="0 0 13 12" width="13" height="12" xmlns="http://www.w3.org/2000/svg"> <path d="M2.86226 11.9524L6.50042 9.82854V1.56715e-07C6.37535 -0.00011728 6.25023 0.065768 6.19279 0.197656L4.5392 3.98541L0.305814 4.33745C0.0115322 4.36199 -0.107248 4.71661 0.115632 4.90329L3.32738 7.59683L2.36512 11.6029C2.29839 11.8807 2.60935 12.0996 2.86226 11.9524Z" />  <path d="M10.1379 11.9527L6.5 9.82881V1.56719e-07C6.62506 -0.000117284 6.75017 0.0657698 6.80761 0.197661L8.46109 3.98553L12.6942 4.33757C12.9885 4.36212 13.1072 4.71674 12.8844 4.90342L9.67284 7.59705L10.635 11.6032C10.7018 11.881 10.3908 12.1 10.1379 11.9527Z" /> </svg>'
        :
        '<svg class="' + (review.rating > 0 ? 'filled' : '') + '" viewBox="0 0 13 12" width="13" height="12" xmlns="http://www.w3.org/2000/svg"> <path d="M2.86226 11.9524L6.50042 9.82854V1.56715e-07C6.37535 -0.00011728 6.25023 0.065768 6.19279 0.197656L4.5392 3.98541L0.305814 4.33745C0.0115322 4.36199 -0.107248 4.71661 0.115632 4.90329L3.32738 7.59683L2.36512 11.6029C2.29839 11.8807 2.60935 12.0996 2.86226 11.9524Z" />  <path d="M10.1379 11.9527L6.5 9.82881V1.56719e-07C6.62506 -0.000117284 6.75017 0.0657698 6.80761 0.197661L8.46109 3.98553L12.6942 4.33757C12.9885 4.36212 13.1072 4.71674 12.8844 4.90342L9.67284 7.59705L10.635 11.6032C10.7018 11.881 10.3908 12.1 10.1379 11.9527Z" /> </svg>' +
                        '<svg class="' + (review.rating > 1 ? 'filled' : '') + '" viewBox="0 0 13 12" width="13" height="12" xmlns="http://www.w3.org/2000/svg"> <path d="M2.86226 11.9524L6.50042 9.82854V1.56715e-07C6.37535 -0.00011728 6.25023 0.065768 6.19279 0.197656L4.5392 3.98541L0.305814 4.33745C0.0115322 4.36199 -0.107248 4.71661 0.115632 4.90329L3.32738 7.59683L2.36512 11.6029C2.29839 11.8807 2.60935 12.0996 2.86226 11.9524Z" />  <path d="M10.1379 11.9527L6.5 9.82881V1.56719e-07C6.62506 -0.000117284 6.75017 0.0657698 6.80761 0.197661L8.46109 3.98553L12.6942 4.33757C12.9885 4.36212 13.1072 4.71674 12.8844 4.90342L9.67284 7.59705L10.635 11.6032C10.7018 11.881 10.3908 12.1 10.1379 11.9527Z" /> </svg>' +
                        '<svg class="' + (review.rating > 2 ? 'filled' : '') + '" viewBox="0 0 13 12" width="13" height="12" xmlns="http://www.w3.org/2000/svg"> <path d="M2.86226 11.9524L6.50042 9.82854V1.56715e-07C6.37535 -0.00011728 6.25023 0.065768 6.19279 0.197656L4.5392 3.98541L0.305814 4.33745C0.0115322 4.36199 -0.107248 4.71661 0.115632 4.90329L3.32738 7.59683L2.36512 11.6029C2.29839 11.8807 2.60935 12.0996 2.86226 11.9524Z" />  <path d="M10.1379 11.9527L6.5 9.82881V1.56719e-07C6.62506 -0.000117284 6.75017 0.0657698 6.80761 0.197661L8.46109 3.98553L12.6942 4.33757C12.9885 4.36212 13.1072 4.71674 12.8844 4.90342L9.67284 7.59705L10.635 11.6032C10.7018 11.881 10.3908 12.1 10.1379 11.9527Z" /> </svg>' +
                        '<svg class="' + (review.rating > 3 ? 'filled' : '') + '" viewBox="0 0 13 12" width="13" height="12" xmlns="http://www.w3.org/2000/svg"> <path d="M2.86226 11.9524L6.50042 9.82854V1.56715e-07C6.37535 -0.00011728 6.25023 0.065768 6.19279 0.197656L4.5392 3.98541L0.305814 4.33745C0.0115322 4.36199 -0.107248 4.71661 0.115632 4.90329L3.32738 7.59683L2.36512 11.6029C2.29839 11.8807 2.60935 12.0996 2.86226 11.9524Z" />  <path d="M10.1379 11.9527L6.5 9.82881V1.56719e-07C6.62506 -0.000117284 6.75017 0.0657698 6.80761 0.197661L8.46109 3.98553L12.6942 4.33757C12.9885 4.36212 13.1072 4.71674 12.8844 4.90342L9.67284 7.59705L10.635 11.6032C10.7018 11.881 10.3908 12.1 10.1379 11.9527Z" /> </svg>' +
                        '<svg class="' + (review.rating > 4 ? 'filled' : '') + '" viewBox="0 0 13 12" width="13" height="12" xmlns="http://www.w3.org/2000/svg"> <path d="M2.86226 11.9524L6.50042 9.82854V1.56715e-07C6.37535 -0.00011728 6.25023 0.065768 6.19279 0.197656L4.5392 3.98541L0.305814 4.33745C0.0115322 4.36199 -0.107248 4.71661 0.115632 4.90329L3.32738 7.59683L2.36512 11.6029C2.29839 11.8807 2.60935 12.0996 2.86226 11.9524Z" />  <path d="M10.1379 11.9527L6.5 9.82881V1.56719e-07C6.62506 -0.000117284 6.75017 0.0657698 6.80761 0.197661L8.46109 3.98553L12.6942 4.33757C12.9885 4.36212 13.1072 4.71674 12.8844 4.90342L9.67284 7.59705L10.635 11.6032C10.7018 11.881 10.3908 12.1 10.1379 11.9527Z" /> </svg>'
}
                </div>
              </div>
                ${review.assist? '<p class="reviews-block__assist">Ассист компания: <span>' + review.assist  + '</span></p>': ''}
                ${review.country? '<p class="reviews-block__country">Страна поездки: <span>' + review.country.name + '</span> </p>': ''}

            <a
              class="reviews-block__text-title"
              href="https://polis812.ru/blog/rating/reviews/review/?id=${review.id}"
              target="_blank"
            >
              ${review.review_title }
            </a>

            <p class="reviews-block__text-content">
              <span>
                ${review.review_text.length > (isMobile.matches ? 250 : 500)
        ? review.review_text.substring(
            0,
            isMobile.matches ? 250 : 500,
        ) + '...'
        : review.review_text}
              </span>
              ${review.review_text.length > (isMobile.matches ? 250 : 500)
        ?
        '<a href="https://polis812.ru/blog/rating/reviews/review/?id=' + review.id + '">Читать далее</a>': '' }
            </p>

            <div class="reviews-block__author">
              <div class="reviews-block__author-meta">
                <p>${ review.name } ${ review.surname }</p>
                <span
                  >${ review.date_create.split(' ')[0]
} ${ review.city ? review.city : '' }</span
                >
              </div>
            </div>
          </div>
        </div>
                    `;
                });
                $('.reviews-list').html(html);
                reviewsSwiper();
            }
        });

};

const setText = () => {
    if (window.innerWidth <= 500) {
        $('#button-large').text('Рассчитать стоимость');
    }
};

$(window).resize(function () {
    sizeItems('.advantages-list__item', '.advantages-container');
    sizeItems('.reviews-block__item', '.reviews-container');
    sizeSmallItems('.insurance-block__card', '.insurances__container')
    setMaxClick();
    setText();
});

$(document).ready(function () {
    // partnerSwiper();
    arrowSwiper();
    insurancesSwiper();
    setText();
    getReviews();

    $('.questions__item').click((event) => {
        if (event.currentTarget.classList.contains('active')) {
            event.currentTarget.classList.remove('active');
        }
        else {
            event.currentTarget.classList.add('active');
        }
    });
    const $cards = $('.insurances .insurance-block__card');
    $cards.on('mouseenter', function () {
        const $card = $(this);
        const $cardBtn = $('.insurance-block__card-btn', $(this));
        const $cardPrice = $('.insurance-block__card-price', $(this));

        if (!$card.hasClass('changing')) {
            $card.addClass('changing');

            $cardPrice.fadeOut(250, function () {
                if ($card.hasClass('for-hide')) {
                    $cardPrice.fadeIn(250).css('display', 'block');
                    $card.removeClass('for-hide');
                } else {
                    $cardBtn.fadeIn(250).css('display', 'block');
                }
                $card.removeClass('changing');
            });
        }
    });

    $cards.on('mouseleave', function () {
        const $card = $(this);
        const $cardPrice = $('.insurance-block__card-price', $(this));
        const $cardBtn = $('.insurance-block__card-btn', $(this));
        if (!$card.hasClass('changing')) {
            $card.addClass('changing');

            $cardBtn.fadeOut(250, function () {
                $cardPrice.fadeIn(250).css('display', 'block');
                $card.removeClass('changing');
            });
        } else {
            $card.addClass('for-hide');
        }
    });

    $('#show-me-menu').on('click', clickHamb);

    function clickHamb() {
        if (!$(this).is('.is-active')) {
            $(this).addClass('is-active');
            $('.mobile-nav').slideToggle(300);
            $('body').addClass('menuOpen');
        } else {
            $(this).removeClass('is-active');
            $('.mobile-nav').slideToggle(300);
            $('body').removeClass('menuOpen');
        }
    }

    var mq = window.matchMedia( "(min-width: 768px)" );

    function scrollToAnchor(aid){
        var aTag = $(aid);
        aTag.get(0).scrollIntoView({behavior: 'smooth'})
    };

    $('.menu-toggle').on('click', function () {
        if (!$(this).hasClass('active')) {
            $('.menu-toggle').removeClass('active');
            $(this).addClass('active');

            if (window.matchMedia('(min-width: 768px)').matches) {
                $('.header-new__menu-desc').slideUp();
                $(this).siblings('.header-new__menu-desc').slideDown();
                $('.header-new__content-item-wrapper').slideUp();
                $('#' + $(this).data('collapse')).slideDown();
            } else {
                $('.header-new__content-item-wrapper').animate({ left: '100%' });
                $('#' + $(this).data('collapse')).animate({ left: '0' });
            }
        }
    });

    $('.header-new__content-back-btn').on('click', function () {
        $('.menu-toggle').removeClass('active');
        $('.header-new__content-item-wrapper').animate({ left: '100%' });
    });

    $('.header-new__menu-item a').on('click', function (e) {
        e.preventDefault();
        setTimeout(() => {
            $('#show-me-menu').removeClass('is-active');
            $('.mobile-nav').slideToggle(300);
            $('body').removeClass('menuOpen');
            scrollToAnchor($(this).attr('href'));
        }, 500)
    });

    $('.promo__button').click((e) => {
        e.preventDefault();
        scrollToAnchor('#calculator')
    });

    $('.header-new__top-link').on('click', function (e) {
        e.preventDefault();
        scrollToAnchor($(this).attr('href'));
    });

    $('#year').text(new Date().getFullYear());
});

window.addEventListener('load', function() {
    var sc = document.createElement('script');
    sc.setAttribute('data-params', 'ZGF0ZV9mcm9tPTE5LjAyLjIwMjM=');
    sc.setAttribute('white-label', 'true');
    sc.setAttribute('data-type', 'vzr');
    sc.setAttribute('data-title', '');
    sc.setAttribute('data-subtitle', '');
    sc.setAttribute('show-companies', 'false');
    sc.setAttribute('theme', 'polis');
    sc.setAttribute('colors', '%7B%22primary%22%3A%22%236FA9FA%22%2C%22secondary%22%3A%22%237CB1FB%22%2C%22accent%22%3A%22%23FFAD32%22%2C%22accentHover%22%3A%22%23FFB74C%22%2C%22secondaryLight%22%3A%22%23F1F6FB%22%2C%22calculatorBlock1%22%3A%22%23AAE9C4%22%2C%22calculatorBlock2%22%3A%22%2327AE60%22%2C%22calculatorBlock3%22%3A%22%23C5DDFE%22%7D');
    sc.setAttribute('partner', '9999');
    sc.setAttribute('theme', 'polis');

    sc['type'] = 'application/javascript';
    sc['src'] = `https://polis812.ru/wl/loader.js`;

    const el = document.getElementById('polis-wl');
    el.parentElement.insertBefore(sc, el);
});
